<template>
    <div>
        <div class="modal fade" id="storageModal" tabindex="-1" aria-labelledby="storageModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="storageModalLabel" class="font-weight-bold">{{ModalTitle}}{{StrgName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Kode</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="StorageId" v-model="StrgId" class="font-weight-bold" />
                                    <label id="errorStrgId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                             <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="StorageName" v-model="StrgName" class="font-weight-bold" />
                                    <label id="errorStrgName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                             <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Tipe Storage</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="StorageType" class="font-weight-bold" :options="StrgTypeData" :value.sync="StrgType" />
                                    <label id="errorStrgType" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import storageService from '../Script/StorageServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'StorageForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            StrgId: '',
            StrgName: '',
            StrgType: '',
            StrgTypeData: [],
            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Storage: '       
            document.getElementById('StorageId').readOnly = false;

            this.StrgId = '';
            this.StrgName = ''

            this.StrgTypeData = [ {value: 'Produksi', label: 'Produksi'}, {value: 'Logistik', label: 'Logistik'} ];
            this.StrgType = 'Produksi';

            this.SaveType = 'Add';
            window.$('#storageModal').modal('show');
        },
        editClick(storageData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Storage: '
            document.getElementById('StorageId').readOnly = true;

            this.StrgId = storageData.storage_id;
            this.StrgName = storageData.storage_name;

            
            this.StrgTypeData = [ {value: 'Produksi', label: 'Produksi'}, {value: 'Logistik', label: 'Logistik'} ];
            this.StrgType = storageData.storage_type;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Storage: ';
            }

            window.$('#storageModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.StrgId == ''){
                this.errorShow('errorStrgId');
            }
            if(this.StrgName == ''){
                this.errorShow('errorStrgName');
            }
            if(this.StrgType == ''){
                this.errorShow('errorStrgType');
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                if(this.SaveType == 'Add'){
                    const storageData = {
                        storage_id: this.StrgId,
                        storage_name: this.StrgName,
                        storage_type: this.StrgType,
                        active_flag: 'A'
                    };

                    const variables = {
                        data : storageData
                    }
                    
                    storageService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#storageModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    const storageData = {
                        storage_id: this.StrgId,
                        storage_name: this.StrgName,
                        storage_type: this.StrgType,                        
                        active_flag: 'A'
                    };

                    const variables = {
                        id : this.StrgId,
                        data : storageData
                    }
                    
                    storageService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#storageModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

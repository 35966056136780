import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class StorageService {
    readQuery(){
        var query = `query{
            GetStorage{
                storage_id,
                storage_name,
                storage_type
            }
        }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:String!){
            DeleteStorage(SID:$id){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewStorage!){
            CreateStorage(input:$data){
                response
            }
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
    
    async editQuery(variables){
    const query = gql`mutation($id:String!,$data:NewStorage!){
            UpdateStorage(SID:$id,New:$data){
                response
            }
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
}


export default new StorageService();
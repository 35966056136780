<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Storage</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <storage-grid :key="gridReload" :deleteClick="deleteClick" :editClick="editClick"/>
                <storage-form ref="storageForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import storageService from '../Storage/Script/StorageServices.js';
import storageGrid from '../Storage/Grid/StorageGrid.vue';
import storageForm from '../Storage/Component/StorageForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Storage',
    components: {
        'storage-grid': storageGrid,
        'storage-form': storageForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Storage');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.storageForm.addClick();
        },
        editClick(data, view){
            this.$refs.storageForm.editClick(data, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id.toString()
                }

                if (result.isConfirmed == true) {
                    storageService.deleteQuery(variables).then(res =>{
                        this.$swal("Info", response.successDelete, "success");
                        this.reload();
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>